import Vue from "vue";
//import VueMeta from 'vue-meta';
import VueRouter from "vue-router";

Vue.use(VueRouter);
//Vue.use(VueMeta);

const isRealestech = window.location.hostname.endsWith(process.env.VUE_APP_HostName);

const routes = [
  {
    path: "/",
    meta: {
      title: 'Realestech',
      /*
      metaTags: [
        {
          name: 'description',
          content: 'Real Estech - Real Estate Platform'
        },
        {
          property: 'og:description',
          content: 'Real Estech - Real Estate Platform'
        }
      ]
      */
    },

    //redirect: "login",
    redirect: to => {
      //console.log(window.location.hostname, isRealestech == true, process.env.VUE_APP_HostName);
      if (isRealestech)
        return '/dashboard';
      // else
      //   return '/test';
    }    
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
    meta: {
      title: 'Realestech - Login'
    }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/DashboardView.vue"),
    meta: {
      title: 'Realestech - Dashboard'
    }
  },
  {
    path: "/search:id?",
    name: "search",
    component: () => import("@/views/SearchView.vue"),
    meta: {
      title: 'Realestech - Search'
    }
  },
  {
    path: "/tasks",
    name: "tasks",
    component: () => import("@/views/TasksView.vue"),
    meta: {
      title: 'Realestech - Tasks'
    }
  },
  {
    path: "/add-property",
    name: "add-property",
    component: () => import("@/views/AddPropertyView.vue"),
    meta: {
      title: 'Realestech - Add Property'
    }
  },
  {
    path: "/update-property/:id?",
    name: "update-property",
    component: () => import("@/views/UpdatePropertyView.vue"),
    meta: {
      title: 'Realestech - Update Property'
    }
  },
  {
    path: "/prop/:pid/:aid?",
    name: "prop",
    component: () => import("@/views/PropertyView.vue"),
    meta: {
      title: 'Realestech'
    }
  },
  // {
  //   path: "/viewings",
  //   name: "viewings",
  //   component: () => import("@/views/ViewingsView.vue"),
  //   meta: {
  //     title: 'Realestech - Viewings'
  //   }
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to,from,next) => {
  //console.log(to);
  const publicPages = ['/login']; //,'register'
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('token');
  
  if (authRequired && !loggedIn && isRealestech){
    next('/login');
  } else {
    next();
  }
});

export default router;